import React from "react"

//Icons
import { FaAngleRight } from "react-icons/fa"

//Styles
import listStyles from "./list.module.scss"

export default ({ description, items }) => {
  return (
    <section className={listStyles.listContainer}>
      <p className={listStyles.listDescription}>{description}</p>
      <hr className={listStyles.listDivider} />
      <ul className={listStyles.list}>
        {items.map((item, index) => {
          return item[1] !== "none" ? (
            <li className={listStyles.listItem} key={index}>
              <input
                  type="checkbox"
                  className={listStyles.listItemCheckbox}
                  id={`${item[0]}${index}`}
                />
              <label
                htmlFor={`${item[0]}${index}`}
                className={listStyles.listItemTitle}
              >
                <span>{item[0]}</span> <FaAngleRight className={listStyles.listItemArrow}/>
              </label>
              <div className={listStyles.listItemDescription}>{item[1]}</div>
            </li>
          ) : (
            <li className={listStyles.listItemSingle} key={index}>{item[0]}</li>
          )
        })}
      </ul>
    </section>
  )
}
